import React from 'react';
import { Link } from 'gatsby';
import Img from 'gatsby-image';

function ProjectPreview ({project, last}) {
    // let styles = {
    //     background: `url(https:${project.previewPhoto.fluid})`,
    // }
    let styles = {}
    if (last) styles['margin'] = '0 auto';

    let slug = project.title.split(' ').join('-');
    console.log(project.clientLogos)
    return (
        <div className='project-preview-container' style={styles}>
            <Img className='fluid' fluid={project.previewPhoto.fluid}></Img>
            <div className='overlay'></div>
            <div className='meta'>
                <img className='logo' src={project.clientLogos[0].file.url}></img>
                <h1 className='header'>{project.title}</h1>
                {/* <p className='paragraph'>{project.description}</p> */}
                <Link to={`/project/${slug}`}>View Project</Link>
                {/* <a href={`/project/${slug}`}>View Project</a> */}
            </div>
        </div>

        // <div className='project-preview transition2' style={styles}>
            // <Img className='' fluid={project.previewPhoto.fluid}></Img>
        // </div>
    )
}

export default ProjectPreview;