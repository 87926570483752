import React, { useState } from 'react';
import { graphql } from 'gatsby'

import Head from '../components/Head';
import NavBar from '../components/NavBar';
import ProjectPreview from '../components/ProjectPreview';

import '../scss/projects.scss';

const loadProjects = (projects) => {
    let parsed1 = [];
    let parsed = [];
    console.log(projects[0])
    
    for (let a in projects) {
        if (projects[a].previewPhoto && projects[a].clientLogos) parsed1.push(projects[a])
    }

    let block_count = parsed1.length % 3

    parsed1.map((project, i) => {
        let last = false;
        if (i === (parsed1.length - 1) && block_count === 1)
            last = true
        
        return parsed.push(
            <ProjectPreview project={project} last={last} key={i}/>
        )
    })
    return parsed;
}

function Projects (props) {
    const data = props.data.contentfulProjectCategory;
    const [navOpen, setNavOpen] = useState(false);
    const navCallback = (state) => {
        setNavOpen(state);
    }
    return (
        <div className='projects'>
            <Head url={`https://northboundfilms.com/${props.pageContext.slug}`}></Head>
            <div className='nav-container'>
                <NavBar secondary={true} parentCallback={navCallback}></NavBar>
                <div className={(navOpen ? 'disabled-content' : null)}/>
                <div className={`container transition mobile-margin-top ${(navOpen ? 'shrink' : null)}`}>
                    <div className='content'>
                        <div className='heading'>
                            <h1 className='header'>{data.title}</h1>
                            <h2 className='subheader'>Browse our collection</h2>
                        </div>
                        <div className='project-previews'>
                            {loadProjects(data.projects)}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Projects;

export const pageQuery = graphql`
    query($title: String!) {
        contentfulProjectCategory(title: { eq: $title }) {
            title
            projects { ...ProjectFragment }
        }
    }
`